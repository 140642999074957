@use 'theme';
@use 'fonts';
@use 'breakpoints';
@use 'borders';
@use 'typography';

.wrapper {
  display: none;
  justify-content: center;

  @include breakpoints.up('small') {
    display: flex;
  }
}

.clientsWrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: theme.spacing(0.25);
  padding: theme.spacing(1);
  background-color: theme.get('backgroundColor');
  border-radius: borders.radius('xMd');
}

.client {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  padding: theme.spacing(0, 2);
  height: 48px;
  border-radius: borders.radius('xMd');

  &:not(.activeClient) {
    cursor: pointer;
  }

  &:hover {
    font-weight: fonts.weight('bold');
  }

  &::before {
    @include typography.get('body2');
    display: block;
    content: attr(data-title);
    font-weight: fonts.weight('bold');
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
}

.activeClient {
  background-color: theme.palette('white');
  font-weight: fonts.weight('bold');
}