@use 'theme';
@use 'breakpoints';

.arrow {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;

  &:not(.disabled) {
    cursor: pointer;
  }
}

.rawArrow {
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(.disabled) {
    cursor: pointer;
  }
}

.arrowLeft {
  left: theme.spacing(3);

  @include breakpoints.up('large') {
    left: theme.spacing(7);
  }
}

.arrowRight {
  right: theme.spacing(3);

  @include breakpoints.up('large') {
    right: theme.spacing(7);
  }
}

.disabled {
  opacity: 0.1;
}