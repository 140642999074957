@use "sass:map";

$classes: (
  slider: 'keen-slider',
  arrow: 'slider-arrow',
  dots: 'slider-dots',
  arrowLeft: 'slider-left-arrow',
  arrowRight: 'slider-right-arrow',
  arrowDisabled: 'slider-disabled-arrow',
);

@mixin get($key) {
  & :global(.#{map.get($classes, $key)}) {
    @content;
  }
}
