@use 'theme';
@use 'fonts';
@use 'breakpoints';
@use 'borders';
@use 'transitions';
@use 'slider';

.modalContent {
  display: flex;
  flex-direction: column;
  width: calc(100vw - 40px);
  height: 100%;
  max-width: 960px;
}

.modalHeader {
  display: flex;
}

.modalTitle {
  display: flex;
  align-items: center;
  flex: 1;
  padding: theme.spacing(2);
  color: theme.palette('white');
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 64px;
  width: 64px;
}

.closeIcon {
  width: 16px;
  height: 16px;
  opacity: 0.8;
  stroke: theme.palette('white');
}

.videoSlider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  max-height: calc(100% - 64px);

  & video {
    width: 100%;
  }
}

.videoSlide {
  display: flex;
  justify-content: center;
}

.videoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.modalArrow {
  position: absolute;
  top: calc(50% - 38px);
  fill: theme.palette('white');
  cursor: pointer;
  padding: theme.spacing(3);
}

.leftArrow {
  left: -82px;
}

.rightArrow {
  left: calc(100% + 18px);
}

.sliderWrapper {
  border-radius: borders.radius('xLg');
  margin-top: theme.spacing(2);

  @include slider.get('arrow') {
    display: none;
  }

  @include breakpoints.up('small') {
    width: 100%;
    overflow: hidden;

    @include slider.get('arrowRight') {
      right: 0;
    }

    @include slider.get('arrowLeft') {
      left: 0;
    }

    @include slider.get('arrow') {
      display: flex;
      height: 100%;
      opacity: 1;
      transition: transitions.get('opacity');
      padding: theme.spacing(0, 3);
      width: 100px;
    }
  }
}

.sliderBox {
  background-color: theme.get('backgroundColor');

  @include breakpoints.up('small') {
    padding-bottom: theme.spacing(4);
  }

  .boxDots {
    padding: theme.spacing(0, 8, 6, 8);
    
    @include breakpoints.up('small') {      
      & > * {
        justify-content: flex-start;
      }
    }
      
    @include breakpoints.up('largeMedium') {
      margin-top: theme.spacing(-2.5);
    }
  }
}

.slider {
  border-radius: borders.radius('md');
}

.slide {
  overflow: visible;
  margin-top: theme.spacing(3);
  height: calc(100% - theme.spacing(3));

  @include breakpoints.up('small') {
    margin-top: theme.spacing(1);
    height: calc(100% - theme.spacing(1));
  }
}

.slideWithVideo {
  cursor: pointer;
}

.arrow {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  &:not(.disabled) {
    cursor: pointer;
  }
}

.arrowLeft {
  left: theme.spacing(7);
  transform: translateX(-50%) rotate(180deg);
}

.arrowRight {
  right: theme.spacing(7);
  transform: translateX(-50%);
}

.disabled {
  opacity: 0.1;
}

.clientsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: theme.spacing(0.25);
  padding: theme.spacing(2);
  background-color: theme.get('backgroundColor');
}

.client {
  padding: theme.spacing(2);

  &:not(.activeClient) {
    cursor: pointer;
  }
}

.activeClient {
  font-weight: fonts.weight('bold');
}