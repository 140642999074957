@use 'theme';
@use 'fonts';
@use 'breakpoints';
@use 'typography';

$imageSize: 138px;
$boxImageSize: 100px;

.wrapper {
  position: relative;
  text-align: center;
  flex: 1;
  height: 100%;
  min-width: min-content;
  padding: theme.spacing(0, 4, 6);

  @include breakpoints.up('small') {
    padding: theme.spacing(0, 8, 6);
  }

  &.box {
    text-align: left;
    justify-content: flex-start;
    padding: theme.spacing(3, 3, 0);
    height: 100%;

    @include breakpoints.up('small') {
      padding: theme.spacing(6, 8, 0);
    }
  }
}

.quotationIcon {
  position: absolute;
  top: 28px;
  right: 28px;
}

.subtitle {
  margin-bottom: theme.spacing(0.25);
}

.imageWrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: theme.spacing(4);
  margin-bottom: theme.spacing(2.5);

  .box & {
    justify-content: flex-start;
  }

  @include breakpoints.up('small') {
    flex-direction: row;
    margin-bottom: theme.spacing(0);
    height: calc($imageSize - theme.spacing(1));
  }
}

.image {
  width: $imageSize;
  height: $imageSize;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;

  .box & {
    width: $boxImageSize;
    height: $boxImageSize;
  }

  @include breakpoints.up('small') {
    top: theme.spacing(-1);
  }
}

.description {
  max-width: 777px;
  margin: theme.spacing(3, 'auto', 0);
  letter-spacing: 0.2px;

  .descriptionText {
    display: block;
    text-align: center;
  }

  .box & {
    max-width: none;
  }

  @include breakpoints.up('small') {
    @include typography.get('h2');

    .box & {
      margin: theme.spacing(0);

      .descriptionText {
        text-align: left;
      }
    }
  }
}

.author {
  margin-top: theme.spacing(4);
  line-height: 18px;
  text-align: center;

  @include breakpoints.up('small') {
    margin-top: theme.spacing(3);

    .box & {
      text-align: left;
    }
  }
}
